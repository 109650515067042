body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.szh-menu-button {
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background: transparent;
  outline: none;
  border: none;
}

.szh-menu {
  background-color: rgb(0, 56, 109) !important;
}

.szh-menu__item {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.szh-menu__item--hover {
  background-color: #004f92 !important;
}

.insetBottom::after {
  /* box-shadow: inset 0 0 20px; */
  box-shadow: inset 0 -20px 20px -20px rgba(255 255 255 / 0.8);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  /* background: linear-gradient(90deg, rgba(50,50,50,0.3),rgba(255,255,255, 0.2), rgba(0,0,0,0)); */
  /* backdrop-filter: blur(1px); */
 }
 
 .insetTop::after {
   /* box-shadow: inset 0 0 20px; */
   box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
   content: '';
   display: block;
   height: 100%;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 2;
   pointer-events: none; 
  }
  

  /* * Image Cascade Animation*/
@keyframes clip1{
  0%{clip-path: polygon(0% -50%, 50% -50%, -50% 50%, -100% 50% );}
  100%{clip-path: polygon(150% 50%, 200% 50%, 100% 150%, 50% 150%);}
}
@keyframes clipa{
  0%{clip-path: polygon(-5% -55%, 55% -45%, -45% 55%, -95% 55% );}
  100%{clip-path: polygon(150% 50%, 200% 50%, 100% 150%, 50% 150%);}
}
@keyframes clip2{
  0%{clip-path: polygon(50% -50%, 100% -50%, 200% 50%, 150% 50%);}
  100%{clip-path: polygon(-100% 50%, -50% 50%, 50% 150%, 0% 150%);}
}
@keyframes clipb{
  0%{clip-path: polygon(40% -60%, 100% -50%, 190% 40%, 150% 50%);}
  100%{clip-path: polygon(-100% 50%, -50% 50%, 50% 150%, 0% 150%);}
}
@keyframes clip3{
  0%{clip-path: polygon(-100% -150%, -50% -150%, -150% -50%, -200% -50%);}
  100%{clip-path: polygon(150% 50%, 200% 50%, 100% 150%, 50% 150%);}
}
@keyframes clip4{
  0%{clip-path: polygon(150% -150%, 200% -150%, 300% -50%, 250% -50%);}
  100%{clip-path: polygon(-100% 50%, -50% 50%, 50% 150%, 0% 150%);}
}

.insetContainer::after
{
 /* box-shadow: inset 0 0 20px; */
 box-shadow: inset 0 0 15px 15px rgba(0 0 0 / 0.2);
 content: '';
 display: block;
 height: 100%;
 position: absolute;
 top: 0;
 width: 100%;
 z-index: 3;
 pointer-events: none;
}
